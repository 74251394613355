import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const PingOneCreateIntegration: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>On the "Applications" tab, click "+".</Text>

    <Img
      priority
      height={184}
      src="/admin-portal/sso/ping-one/v1/ping-one-1-updated.png"
      width={830}
    />

    <Text>
      Select "Advanced Configuration" and choose the "SAML" connection type.
    </Text>

    <Img
      height={325}
      src="/admin-portal/sso/ping-one/v1/ping-one-2-updated.png"
      width={830}
    />

    <Text>
      Give it a descriptive "Application Name" to easily identify it later such
      as {appName}-{connection?.name}. Then fill out the "Application
      Description."
    </Text>

    <Img
      height={236}
      src="/admin-portal/sso/ping-one/v1/ping-one-3-updated.png"
      width={830}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve finished creating the SAML Integration and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneSubmitAcsUrl: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>
      Submit ACS URL as the "Assertion Consumer Service (ACS) URL" and the
      Entity ID as the "Entity ID".
    </Text>

    <Img
      height={442}
      src="/admin-portal/sso/ping-one/v1/ping-one-4-updated.png"
      width={830}
    />

    <Text>Click "Continue to Next Step".</Text>

    <Confirm
      label={
        'I\'ve configured the Assertion Consumer Service (ACS) URL and Entity ID and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      On the "SSO Attribute Mapping" step, click on the "Add new attribute"
      button to add new attributes to map.
    </Text>

    <Img
      priority
      height={477}
      src="/admin-portal/sso/ping-one/v1/ping-one-5-updated.png"
      width={830}
    />

    <Text>
      Fill in the following Attribute Statements and click “Continue to Next
      Step”:
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label={
        'I\'ve finished configuring the Attribute Statements and clicked "Continue to Next Step".'
      }
      onClick={handleNextStep}
    />
  </>
);

export const PingOneUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    idpSsoUrlPlaceholder="https://sso.connect.pingidentity.com/sso/sp/initsso..."
    metadataUrlPlaceholder="https://auth.pingone.com/438a423-0052-4234-a3e1-37244316b20b/saml20/metadata/bcd06244-183d-4534-89c5-acbbbc65b53e"
  >
    <Text>
      At the end of the Application Setup Wizard, you'll be given an overview of
      your Identity Provider metadata. Copy the "Initiate Single Sign-On (SSO)
      URL" to use as the Identity Provider Single Sign-On URL below.
    </Text>

    <Img
      height={600}
      src="/admin-portal/sso/ping-one/v1/ping-one-7.png"
      width={830}
    />

    <Text>Paste the Metadata URL in the field below.</Text>
  </MetadataUrlStep>
);
