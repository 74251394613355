import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsRippling: ArrayStepsConfig = {
  providerLabel: 'Rippling',
  type: 'Array',
  steps: [
    {
      name: 'Generate API Key in Rippling',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            If you are an admin of your Rippling instance, log in and select the
            Settings icon in the left side menu of the Rippling console, then
            select "Company Settings" in the menu.
          </Text>

          <Img
            priority
            height={1070}
            src="/admin-portal/directory-sync/rippling/v1/rippling-1.png"
            width={758}
          />

          <Text>
            Select "API Access" from the top menu of the Company Settings page,
            and then select "Create API Key".
          </Text>

          <Img
            priority
            height={536}
            src="/admin-portal/directory-sync/rippling/v1/rippling-2.png"
            width={2242}
          />

          <Text>
            Give your key a descriptive name such as <strong>{appName}</strong>.
          </Text>

          <Img
            height={550}
            src="/admin-portal/directory-sync/rippling/v1/rippling-3.png"
            width={1162}
          />

          <Text>
            WorkOS needs at least the following permissions selected (any other
            information needed by your application may require additional
            permissions):
          </Text>

          <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
            <li>Company Departments</li>
            <li>Company Teams</li>
            <li>Employee First name, last name</li>
            <li>Employee Work email</li>
            <li>Employee Department</li>
            <li>Employee Teams</li>
            <li>Read Employee Department Id</li>
          </ul>

          <Text>
            Select "Save" and then copy the generated key. You’ll upload this
            key in the next step.
          </Text>

          <Img
            height={788}
            src="/admin-portal/directory-sync/rippling/v1/rippling-4.png"
            width={1574}
          />

          <Confirm
            label="I've generated my Rippling API key."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Rippling API Key',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange: handleInputChange,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>Upload the API key you generated in your last step.</Text>

          <Card>
            <Form
              disabled={!directory?.rippling_api_key}
              isLoading={isLoading}
              isUpdate={!!errors?.rippling_api_key}
              onSubmit={handleNextStep}
            >
              <InputGroup
                autoFocus
                error={errors?.rippling_api_key?.message}
                id="rippling_api_key"
                label="1. Rippling API Key"
                name="rippling_api_key"
                onChange={handleInputChange}
                placeholder=""
                type="password"
                value={directory?.rippling_api_key ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            Rippling by default will provide the following additional attributes
            that are available for mapping: "id", "name", "firstName",
            "lastName", "employmentType", "title", "gender", "identifiedGender",
            "department", "workLocation", "spokeId", "endDate", "roleState",
            "workEmail" and "manager". The full list of available attributes
            from Rippling can be found{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://developer.rippling.com/docs/rippling-api/b3A6OTgxMjA2-get-employee'
              }
            >
              here.
            </Link>
          </Text>

          <Text>
            Note that available fields for a single employee will depend on the
            scopes that your API key or access token have access to.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
