import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsOneLogin: ArrayStepsConfig = {
  providerLabel: 'OneLogin SCIM v2',
  type: 'Array',
  steps: [
    {
      name: 'Select or Create OneLogin Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to the OneLogin admin dashboard and select the "Applications"
            tab at the top. Select "Add App".
          </Text>

          <Img
            height={546}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-4.png"
            width={1446}
          />

          <Text>
            Search for "scim" in the text field and select the Application with
            type "SCIM Provisioner with SAML (SCIM V2 Enterprise)".
          </Text>

          <Img
            height={725}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-5.png"
            width={1446}
          />

          <Text>
            Give your Application a descriptive Display Name and hit "Save".
          </Text>

          <Img
            height={714}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-6.png"
            width={1446}
          />

          <Confirm
            label="I’ve selected or created my OneLogin application."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure OneLogin SCIM',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Within the SCIM Application, select the "Configuration" tab on the
            left.
          </Text>

          <Text>
            Copy and paste the endpoint below into the "SCIM Base URL"
          </Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Then, copy and paste the Bearer Token below into the "SCIM Bearer
            Token" field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearer_token}
          />

          <Text>Hit "Enable" under "API Status" and then hit "Save".</Text>

          <Img
            height={857}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-7-edit.png"
            width={1284}
          />

          <Text>
            Select the "Provisioning" tab on the left. Check the "Enable
            provisioning" box and hit "Save".
          </Text>

          <Img
            height={823}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-8.png"
            width={1446}
          />

          <Text>
            Select the "Parameters" tab on the left. Then select "Groups".
          </Text>

          <Img
            height={796}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-21.png"
            width={1446}
          />

          <Text>
            In the modal that pops up, check the box next to "Include in User
            Provisioning" and hit "Save".
          </Text>

          <Img
            height={857}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-22.png"
            width={980}
          />

          <Confirm
            label="I’ve confirmed, activated and saved the configuration settings."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign Users to your Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In order for your users and groups to be synced, you will need to
            assign them to your OneLogin Application. Select "Users" from the
            top navigation menu.
          </Text>

          <Text>
            Next, find a user you'd like to provision to the SCIM app. Within
            that user profile, select the "Applications" tab on the left. Then,
            click the "+" symbol.
          </Text>

          <Img
            height={603}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-9.png"
            width={1446}
          />

          <Text>Select the appropriate app and hit "Continue".</Text>

          <Img
            height={746}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-10.png"
            width={1382}
          />

          <Text>Select "Save" in the next modal to confirm the change.</Text>

          <Img
            height={857}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-11.png"
            width={927}
          />

          <Confirm
            label="I’ve completed user provisioning."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign Groups to your Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            NOTE: There are many ways to provision groups in OneLogin. Below is
            one method that we recommend, but other methods can be used.
          </Text>

          <Text>
            In the top navigation, Select "Users" and then "Roles" from the
            dropdown. Select "New Role".
          </Text>

          <Img
            height={620}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-14.png"
            width={1446}
          />

          <Text>
            Give the Role a name (this will be the name of the group), select
            the appropriate SCIM application, and hit "Save".
          </Text>

          <Img
            height={497}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-15.png"
            width={1446}
          />

          <Text>
            Click the "Users" tab for the role. Search for any users you'd like
            to assign to that role and hit "Add To Role". Then hit "Save".
          </Text>

          <Img
            height={839}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-16.png"
            width={1446}
          />

          <Text>Click "Save" in the next modal to confirm.</Text>

          <Img
            height={518}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-17.png"
            width={1382}
          />

          <Text>
            Navigate back to your SCIM app and click on the "Rules" tab on the
            left. Then, hit "Add Rule".
          </Text>

          <Img
            height={752}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-18.png"
            width={1446}
          />

          <Text>
            Give your Rule a name. Under "Actions", select "Set Groups in
            your-app-name". Then, set it to "For each role with value that
            matches your-role-name". Hit "Save".
          </Text>

          <Img
            height={857}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-19.png"
            width={1435}
          />

          <Text>
            Within your SCIM app under the Users tab, you may then need to click
            on any "Pending" notifications to confirm the update for users.
          </Text>

          <Img
            height={663}
            src="/admin-portal/directory-sync/onelogin/v1/onelogin-20.png"
            width={1446}
          />

          <Confirm
            label="I’ve completed my assignments."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      // eslint-disable-next-line react/display-name
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            OneLogin offers the following list of{' '}
            <Link
              newTab
              appearance="highlight"
              href={'https://developers.onelogin.com/scim/define-user-schema'}
            >
              default attribute mappings
            </Link>{' '}
            in their documentation. We support both the Core and Enterprise User
            Schemas from OneLogin.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
