import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributes } from '../map-attributes';
import { ArrayStepsConfig } from './steps-config';

export const stepsWorkday: ArrayStepsConfig = {
  providerLabel: 'Workday',
  type: 'Array',
  steps: [
    {
      name: 'Create an Integration System User',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            First, create a new Integration System User within Workday. The
            Integration System User will be used to access Custom Reports.
          </Text>

          <Img
            priority
            height={613}
            src="/admin-portal/directory-sync/workday/v1/workday-1.png"
            width={1832}
          />

          <Confirm
            label="I've created the Integration System User."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Create a Security Group',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Create a new security group in Workday. Set the Type of Tenanted
            Security Group to Integration System Security Group (Unconstrained).
            Then add a name for the Security Group and select OK.
          </Text>

          <Img
            priority
            height={537}
            src="/admin-portal/directory-sync/workday/v1/workday-2.png"
            width={690}
          />

          <Text>
            Next, for Integration System Users, add the integration system user
            you created in the previous step, and select OK.
          </Text>

          <Img
            priority
            height={408}
            src="/admin-portal/directory-sync/workday/v1/workday-3.png"
            width={836}
          />

          <Confirm
            label="I've created the Security Group."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Add domain security policies to the Security Group',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Next, you'll need to add domain security policies to the newly
            created security group. You can access this on the Security Group
            Settings {'>'} Maintain Domain Permissions for Security Group page.
          </Text>

          <Img
            priority
            height={522}
            src="/admin-portal/directory-sync/workday/v1/workday-4.png"
            width={1010}
          />

          <Text>
            You'll need to permit the following domain security policies to have
            Get access under Integration Permissions:
          </Text>

          <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
            <li>Person Data: Work Contact Information</li>
            <li>Workday Accounts</li>
            <li>Worker Data: Active and Terminated Workers</li>
            <li>Worker Data: All Positions</li>
            <li>Worker Data: Business Title on Worker Profile</li>
            <li>Worker Data: Current Staffing Information</li>
            <li>Worker Data: Public Worker Reports</li>
            <li>Worker Data: Workers</li>
          </ul>

          <Img
            height={328}
            src="/admin-portal/directory-sync/workday/v1/workday-5.png"
            width={629}
          />

          <Text>
            To activate these new security settings, you need to go to the
            Activate Pending Security Policy Changes page and click OK.
          </Text>

          <Img
            height={1194}
            src="/admin-portal/directory-sync/workday/v1/workday-6.png"
            width={953}
          />

          <Text>Then, select the Confirm checkbox to finish activating.</Text>

          <Img
            height={354}
            src="/admin-portal/directory-sync/workday/v1/workday-7.png"
            width={742}
          />

          <Confirm
            label="I've added the domain security policies to the security group and activated them."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Create and populate custom reports',
      // eslint-disable-next-line react/display-name
      render: ({
        onNextStep: handleNextStep,
        directoryCustomAttributes,
      }: DirectoryStepProps) => (
        <>
          <Text>
            You will need to create two Custom Reports. The first Custom Report
            will be used for syncing User information. The second report will be
            used for syncing Group information.
          </Text>

          <Text>
            When creating the report, make sure to select the Advanced report
            type and to have the Enable as Web Service box checked.
          </Text>

          <Img
            priority
            height={586}
            src="/admin-portal/directory-sync/workday/v1/workday-8.png"
            width={1124}
          />

          <Text>
            You need to add information for certain fields to the report. You
            can do this by directly adding columns to the report for the
            attributes in Workday that contain the following information:
          </Text>

          <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
            <li>Unique ID</li>
            <li>Username</li>
            <li>Email</li>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Group Name</li>

            {directoryCustomAttributes?.map((customAttribute) => (
              <li key={customAttribute.id}>{customAttribute.name}</li>
            ))}
          </ul>

          <Img
            height={641}
            src="/admin-portal/directory-sync/workday/v1/workday-9.png"
            width={2480}
          />

          <Text>
            Along the same lines as the User Report, you need to add a column to
            the Group Report for an attribute that contains the following
            information:
          </Text>

          <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
            <li>Group Name</li>
          </ul>

          <Confirm
            label="I've created and populated the custom reports."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Add an authorized user',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Add the Integration User created in Step 1 as an authorized user of
            both the User and Group Reports. This can be found under the Share
            tab from within a Report.
          </Text>

          <Img
            priority
            height={775}
            src="/admin-portal/directory-sync/workday/v1/workday-10.png"
            width={680}
          />

          <Confirm
            label="I've added an authorized user to both the User and Group Reports."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Get the RaaS Endpoint',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Now that the User and Group Reports are setup and access to them has
            been configured, you will need to get the RaaS endpoints for both
            reports. The page with the endpoints can be found under Actions{' '}
            {'>'} Web Service {'>'} View URLs in each report.
          </Text>

          <Img
            priority
            height={651}
            src="/admin-portal/directory-sync/workday/v1/workday-11.png"
            width={990}
          />

          <Text>
            Once on the View URLs page, you will save the URL under the JSON
            section, and input these in the next step.
          </Text>

          <Img
            height={856}
            src="/admin-portal/directory-sync/workday/v1/workday-12.png"
            width={796}
          />

          <Confirm
            label="I've collected the RaaS Endpoints."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Workday endpoints and credentials',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange: handleInputChange,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Upload the RaaS endpoints for the User and Group reports that you
            collected in the last step, as well as the username and password of
            the Integration System User.
          </Text>

          <Card>
            <Form
              disabled={
                !directory?.workday_raas_endpoint ||
                !directory?.workday_raas_group_endpoint ||
                !directory?.workday_raas_username ||
                !directory?.workday_raas_password
              }
              isLoading={isLoading}
              isUpdate={
                !!(
                  errors?.workday_raas_endpoint ||
                  errors?.workday_raas_group_endpoint ||
                  errors?.workday_raas_username ||
                  errors?.workday_raas_password
                )
              }
              onSubmit={handleNextStep}
            >
              <InputGroup
                autoFocus
                error={errors?.workday_raas_endpoint?.message}
                id="workday_raas_endpoint"
                label="User Report Endpoint"
                name="workday_raas_endpoint"
                onChange={handleInputChange}
                placeholder="https://subdomain.workday.com/path/to/report?format=json"
                value={directory?.workday_raas_endpoint ?? undefined}
              />

              <InputGroup
                error={errors?.workday_raas_group_endpoint?.message}
                id="workday_raas_group_endpoint"
                label="Group Report Endpoint"
                name="workday_raas_group_endpoint"
                onChange={handleInputChange}
                placeholder="https://subdomain.workday.com/path/to/report?format=json"
                value={directory?.workday_raas_group_endpoint ?? undefined}
              />

              <InputGroup
                error={errors?.workday_raas_username?.message}
                id="workday_raas_username"
                label="Username"
                name="workday_raas_username"
                onChange={handleInputChange}
                placeholder="username"
                value={directory?.workday_raas_username ?? undefined}
              />

              <InputGroup
                error={errors?.workday_raas_password?.message}
                id="workday_raas_password"
                label="Password"
                name="workday_raas_password"
                onChange={handleInputChange}
                placeholder=""
                type="password"
                value={directory?.workday_raas_password ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Map custom report fields',
      // eslint-disable-next-line react/display-name
      render: ({
        appName,
        directory,
        directoryCustomAttributes,
        onNextStep,
        updateDirectory,
      }: DirectoryStepProps): JSX.Element => (
        <>
          <Text>
            Map the required fields below to the corresponding columns you added
            to the custom reports. You can use the attribute names listed in the
            "Column Heading Override XML Alias" from the Workday custom report.
          </Text>

          {directory && directoryCustomAttributes && (
            <MapAttributes
              appName={appName}
              directory={directory}
              directoryCustomAttributes={directoryCustomAttributes}
              onFinishMapping={async () => {
                await updateDirectory(directory);
                onNextStep();
              }}
            >
              {(buttonProps) => (
                <Confirm
                  label="I’ve finished configuring custom report mappings."
                  {...buttonProps}
                />
              )}
            </MapAttributes>
          )}
        </>
      ),
    },
  ],
};
