import { Button, Label, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Link } from 'components/link';
import { Select } from 'components/select';
import { StoreContext } from 'components/store-provider';
import { DirectoryStepProps } from 'interfaces/step-props';
import React, { useContext } from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsGoogleWorkspace: ArrayStepsConfig = {
  providerLabel: 'Google Workspace',
  type: 'Array',
  steps: [
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            The full list of available attributes from Google Workspace can be
            found{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://developers.google.com/admin-sdk/directory/reference/rest/v1/users'
              }
            >
              here.
            </Link>
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
    {
      name: 'Allow Access to Google Workspace',
      // eslint-disable-next-line react/display-name
      render: ({
        appName,
        directory,
        isLoading,
        domain,
        domains,
        updateDirectory,
      }: DirectoryStepProps) => {
        const {
          domain: [, setDomain],
          // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useContext(StoreContext);

        return (
          <>
            <Text>
              To connect your Google Workspace directory to {appName} you will
              need to allow access to:
            </Text>

            <ul className="my-4 space-y-3 list-disc list-inside text-gray-lightmode-300">
              <li>View your {domain} email address</li>
              <li>View groups on your domain</li>
              <li>View users on your domain</li>
            </ul>

            <Text>
              Click <strong>Allow Access</strong> to continue.
            </Text>

            <Card>
              <Label className="mb-2" htmlFor="google-domain">
                Your Google Workspace Domain
              </Label>

              <Select
                id="google-domain"
                name="google-domain"
                onChange={async (option) => {
                  if (option?.value) {
                    setDomain(option?.value);

                    if (directory) {
                      void updateDirectory(directory, option.value);
                    }
                  }
                }}
                options={domains.map((domain) => ({
                  label: domain,
                  value: domain,
                }))}
                placeholder="Select your Google Workspace Domain"
                selected={domain ? { value: domain, label: domain } : undefined}
              />

              <div className="flex justify-end mt-2">
                <Button
                  className="self-end"
                  disabled={!directory}
                  isLoading={isLoading}
                  onClick={() => {
                    window.location.href = directory?.setup_url || '';
                  }}
                >
                  Allow Access
                </Button>
              </div>
            </Card>
          </>
        );
      },
    },
  ],
};
