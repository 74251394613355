import { SwitchIcon } from '@workos-inc/component-library';
import { ProviderLogo } from 'components/provider-logo';
import { SwitchProviderModal } from 'components/sso/switch-provider-modal';
import { Step, StepsNav } from 'components/steps-nav';
import { StoreContext } from 'components/store-provider';
import { useRouter } from 'next/router';
import React, { FC, useContext, useState } from 'react';
import { trackEvent } from 'utils/track-event';

export type SidebarProps = {
  steps: Step[];
  stepsWithError: number[] | undefined;
};

export const Sidebar: FC<Readonly<SidebarProps>> = ({
  steps,
  stepsWithError = [],
}) => {
  const router = useRouter();
  const {
    ssoProvider: [provider],
  } = useContext(StoreContext);

  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <aside className="sticky top-24 h-fit">
        <div className="w-24">
          {provider && <ProviderLogo provider={provider} />}
        </div>

        <button
          className="flex items-center mt-5 mb-10 bg-none border-none outline-none text-gray-lightmode-300"
          onClick={() => setIsSwitchProviderModalOpen(true)}
        >
          <SwitchIcon className="text-gray-lightmode-300" />
          Switch Identity Provider
        </button>

        <StepsNav
          onChangeStep={(step: number) => void router.push(`/sso/${step}`)}
          provider={provider}
          steps={steps}
          stepsWithError={stepsWithError}
        />
      </aside>

      <SwitchProviderModal
        open={isSwitchProviderModalOpen}
        setOpen={setIsSwitchProviderModalOpen}
        trackEvent={trackEvent}
      />
    </>
  );
};
