import { ErrorFields } from '../../configuration-complete';
import { Step } from '../steps-config';

export const errorFieldsFromSteps = (steps: Step[]): ErrorFields =>
  steps.reduce(
    (fields, { errorFields = {} }, index) =>
      Object.assign(
        fields,
        ...Object.entries(errorFields).map(([fieldName, fieldOptions]) => ({
          [fieldName]: {
            ...fieldOptions,
            step: index + 1,
          },
        })),
      ),
    {},
  );
