import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsBambooHR: ArrayStepsConfig = {
  providerLabel: 'BambooHR',
  type: 'Array',
  steps: [
    {
      name: 'Generate API Key in BambooHR',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to your BambooHR instance as an admin user and select your
            name in the upper right-hand corner of the BambooHR console, then
            select “API keys” in the menu.
          </Text>

          <Img
            priority
            height={356}
            src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-1.png"
            width={412}
          />

          <Text>Select "Add New Key".</Text>

          <Img
            priority
            height={281}
            src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-2.png"
            width={1385}
          />

          <Text>
            Give your key a descriptive name such as <strong>{appName}</strong>{' '}
            and select "Generate Key".
          </Text>

          <Img
            height={322}
            src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-3.png"
            width={632}
          />

          <Text>
            Select "Copy Key" and save this API key, which you'll upload in the
            next step.
          </Text>

          <Img
            height={406}
            src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-4.png"
            width={628}
          />

          <Confirm
            label="I've generated my BambooHR API key."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload BambooHR API Key and Subdomain',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange: handleInputChange,
        onNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Upload the API key you generated in your last step, along with your
            company's BambooHR subdomain. If you access BambooHR at
            https://mycompany.bamboohr.com, then the sudomain is "mycompany".
          </Text>

          <Card>
            <Form
              disabled={
                !directory?.bamboo_hr_api_key || !directory?.bamboo_hr_subdomain
              }
              isLoading={isLoading}
              isUpdate={
                !!(errors?.bamboo_hr_api_key || errors?.bamboo_hr_subdomain)
              }
              onSubmit={() => onNextStep()}
            >
              <InputGroup
                autoFocus
                error={errors?.bamboo_hr_api_key?.message}
                id="bamboo_hr_api_key"
                label="1. BambooHR API Key"
                name="bamboo_hr_api_key"
                onChange={handleInputChange}
                placeholder=""
                type="password"
                value={directory?.bamboo_hr_api_key ?? undefined}
              />

              <InputGroup
                error={errors?.bamboo_hr_subdomain?.message}
                id="bamboo_hr_subdomain"
                label="2. Your Company's BambooHR Subdomain"
                name="bamboo_hr_subdomain"
                onChange={handleInputChange}
                placeholder="mycompany"
                value={directory?.bamboo_hr_subdomain ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <>
          <Text>
            There are additional attributes needed by the application for it to
            fully function.
          </Text>

          <Text>
            BambooHR by default will provide the following additional attributes
            that are available for mapping: "id", "status", "lastName",
            "firstName", "homeEmail", "workEmail", "department", and
            "displayName". However, additional attributes can be added to the
            "raw_attributes" by request.
          </Text>

          <Img
            priority
            height={1200}
            src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-5.png"
            width={1450}
          />

          <Text>
            The full list of available attributes from BambooHR can be found{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://documentation.bamboohr.com/docs/list-of-field-names'
              }
            >
              here
            </Link>
            . If you need attributes other than the ones found on the default
            list, reach out to us at{' '}
            <Link
              newTab
              appearance="highlight"
              href="mailto: support@workos.com"
            >
              support@workos.com
            </Link>{' '}
            and we can add them on your behalf.
          </Text>

          <Text>
            For example, if you wanted to use the value of "workEmail", you
            would enter "workEmail" in the Directory Provider Value field.
          </Text>

          <MapAttributesStep {...directoryStepProps} />
        </>
      ),
    },
  ],
};
