import { InputGroup, Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { DownloadInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const AdfsConfigureRelyingPartyTrust: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>Open the AD FS Management console.</Text>

    <Img
      priority
      height={912}
      src="/admin-portal/sso/adfs/v1/adfs-1.png"
      width={1278}
    />

    <Text>Click “Relying Party Trusts” on the left sidebar.</Text>

    <Img
      priority
      height={912}
      src="/admin-portal/sso/adfs/v1/adfs-2.png"
      width={1278}
    />

    <Text>
      Open the “AD FS Relying Party Trust Wizard” by clicking “Add Relying Party
      Trust...” on the right sidebar.
    </Text>

    <Img height={912} src="/admin-portal/sso/adfs/v1/adfs-3.png" width={1278} />

    <Text>Select “Claims aware” and then “Start”.</Text>

    <Text>Download the provided Metadata file from WorkOS.</Text>

    <DownloadInput
      filename="metadata.xml"
      label="Your SP Metadata File"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>
      Select “Import data about the relying party from a file,” then select the
      SP Metadata file you downloaded, then click “Next”.
    </Text>

    <Img height={912} src="/admin-portal/sso/adfs/v1/adfs-4.png" width={1278} />

    <Text>Select “Permit everyone” and then “Next”.</Text>

    <Img height={912} src="/admin-portal/sso/adfs/v1/adfs-5.png" width={1278} />

    <Confirm
      buttonText="Continue to Next Step"
      label="I’ve selected “Permit everyone” and clicked on “Next”."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsAccessPolicy: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Click the “Endpoints” tab and confirm that the “SAML Assertion Consumer
      Endpoints” matches the URL below and click “Next”.
    </Text>

    <Img
      priority
      height={912}
      src="/admin-portal/sso/adfs/v1/adfs-6.png"
      width={1278}
    />

    <Card>
      <InputGroup
        readOnly
        id="saml_acs_url"
        label="Confirm this URL matches the SAML Assertion Consumer Endpoint"
        name="saml_acs_url"
        value={connection?.saml_acs_url}
      />
    </Card>

    <Text>
      Select “Configure claims issuance policy for this application” and
      “Close”.
    </Text>

    <Img height={912} src="/admin-portal/sso/adfs/v1/adfs-7.png" width={1278} />

    <Confirm
      label="I’ve finished and closed the Relying Party Trust Wizard."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsClaimsPolicy: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click “Add Rule” in the “Edit Claims Issuance Policy” window.</Text>

    <Img
      priority
      height={912}
      src="/admin-portal/sso/adfs/v1/adfs-8.png"
      width={1278}
    />

    <Text>Select “Send LDAP Attributes as Claims” and then “Next”.</Text>

    <Img height={912} src="/admin-portal/sso/adfs/v1/adfs-9.png" width={1278} />

    <Text>Submit “Attributes” as “Claim rule name:”</Text>

    <Text>Select “Active Directory” as “Attribute Store”</Text>

    <Text>Fill in the following Attribute mappings:</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>Click “Finish”.</Text>

    <Img height={582} src="/admin-portal/sso/adfs/v1/adfs-10.png" width={716} />

    <Confirm
      label="I’ve finished and closed the claims issuance policy."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sso.adfs.com/12345abc..."
    idpSsoUrlPlaceholder="https://adfs.test.com/adfs/ls..."
    metadataUrlPlaceholder="https://SERVER/federationmetadata/2007-06/federationmetadata.xml"
  >
    <Text>
      Next you will want to obtain the Metadata URL from your ADFS server. ADFS
      publishes its metadata to a standard URL by default:
      (https://SERVER/federationmetadata/2007-06/federationmetadata.xml) where
      "SERVER" is your federation service FQDN. You can also find your ADFS
      Federation Metadata URL through the AD FS Management in "AD FS -{'>'}{' '}
      Service -{'>'} Endpoints" and navigate to the Metadata section.
    </Text>

    <Img
      priority
      height={1708}
      src="/admin-portal/sso/adfs/v1/adfs-metadata-upload-2.png"
      width={2710}
    />

    <Text>
      Upload the Metadata URL and the connection will be verified and linked!
    </Text>
  </MetadataUrlStep>
);
