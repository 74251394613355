/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties } from 'react';
import { Theme } from 'react-select';

enum Colors {
  Gray = '#828282',
  GrayDark = '#9c9c9c',
  GrayDarker = '#383838',
  GrayLight = '#bdbdbd',
  GrayLighter = '#fbfcfd',
  Primary = '#6363f1',
  TonedMidGray = '#666975',
  White = '#ffffff',
}

export const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: Colors.GrayDark,
    primary50: Colors.GrayLighter,
    primary75: Colors.GrayLight,
    primary: Colors.Gray,
  },
});

const getControlBorderColor = (isFocused: boolean): Colors => {
  if (isFocused) {
    return Colors.Gray;
  }
  return Colors.GrayLight;
};

export const selectStyles = () => ({
  control: (base: CSSProperties, state: any) => ({
    ...base,
    'backgroundColor': Colors.White,
    'borderColor': getControlBorderColor(state.isFocused),
    'borderRadius': '4px',
    'boxShadow': 'none',
    'marginBottom': 0,
    'color': Colors.GrayDarker,
    'transition': '250ms all',
    'width': '100%',
    'minHeight': '36px',
    'cursor': 'pointer',
    '&:hover': {
      borderColor: Colors.Primary,
    },
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    marginRight: '0.5rem',
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    color: Colors.Gray,
    padding: 0,
    marginRight: '0.25rem',
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    padding: '0.25rem',
  }),
  option: (base: CSSProperties, state: any) => ({
    ...base,
    'display': 'flex',
    'alignItems': 'center',
    'fontSize': '13px',
    'backgroundColor': state.isFocused ? Colors.GrayLighter : 'transparent',
    'borderRadius': '4px',
    'cursor': 'pointer',
    ':active': {
      backgroundColor: Colors.GrayLighter,
    },
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    color: Colors.TonedMidGray,
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
  }),
});
