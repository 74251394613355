import React, { CSSProperties, ReactElement } from 'react';
import ReactSelect, { SelectComponentsConfig } from 'react-select';
import { Option } from './option';
import { customTheme, selectStyles } from './styles';

export type SelectOption = {
  label: string;
  value: string;
  selected?: boolean;
  subtitle?: string;
};

type SelectProps = {
  placeholder?: string;
  options: SelectOption[];
  selected?: SelectOption;
  emptyValue?: string;
  onChange(option?: SelectOption): void;
  isClearable?: boolean;
  style?: CSSProperties;
  components?: SelectComponentsConfig<SelectOption, false>;
  containerStyle?: CSSProperties;
  name?: string;
  id?: string;
};

export const Select = ({
  containerStyle,
  placeholder = 'Select an item',
  options = [],
  selected,
  emptyValue,
  onChange: handleChange,
  isClearable,
  name,
  id,
  components: CustomComponents,
}: SelectProps): ReactElement => {
  const components = {
    IndicatorSeparator: () => null,
    Option,
    ...CustomComponents,
  };

  return (
    <ReactSelect
      open
      components={components}
      getOptionLabel={(option: SelectOption) => option.label}
      getOptionValue={(option: SelectOption) => option.value}
      getValue={(option: SelectOption) => option.value}
      id={id}
      isClearable={isClearable}
      isSearchable={false}
      name={name}
      noOptionsMessage={() => emptyValue || null}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={{
        ...selectStyles(),
        container: (base) => ({
          ...base,
          ...containerStyle,
        }),
      }}
      theme={(theme) => customTheme(theme)}
      value={selected && selected.value ? selected : null}
    />
  );
};
