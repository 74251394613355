import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

const CERTIFICATE_FORMAT = `-----BEGIN CERTIFICATE-----
<PUBLIC KEY VALUE>==
-----END CERTIFICATE-----`;

const CERTIFICATE_COMPLETE = `-----BEGIN CERTIFICATE-----
MIIDUTCCAjmgAwIBAgIRAN557boQ2ZxW4Ww08cZYK2IwDQYJKoZIhvcNAQELBQAw
YjELMAkGA1UEBhMCVVMxDjAMxxxxxAgTBVRleGFzMQ8wDQYDVQQHEwZBdXN0aW4x
EzARBgNVBAoTCkNsb3VkZmxhcmUxHTAbBgNVBAMTFGNsb3VkZmxhcmVhY2Nlc3Mu
Y29tMB4XDTxxxxxwMjE5MzMxM1oXDTMyMDIwMjE5MzMxM1owYjELMAkGA1UEBhMC
VVMxDjAMBgNVBAgTBVRleGFzMQ8wDQYDVQQHEwZBdXN0aW4xEzARBgNVBAoTCkNs
b3VkZmxhcmUxHTAbBgNVBAMTFGNsb3VkZmxxxxxhY2Nlc3MuY29tMIIBIjANBgkq
hkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA49p6jXzk65DeG4DI2NgW0UOOJrd+9qYS
OCuBYq/e4IqSeqchsm1JDY9MjB6xmiw+urC1qWuj0MS4dwAJQwiGFbCGDh5m4FAF
mZm5VaMkW5Q+MG5zXRfeLmhvLuT0XVBcDlkwPC3k28/moKi1KlwPcujLF43+rf2d
8Rm6ZNCJgfVzRxxxxxPd5NGpNlEZ0ViPXM1gsO15/1Iginevv+xKqRTx0vMsNLWJ
BwWLAAqm5b6U9XQefwy9lPqPywFwCuZEMXwI9Rpm0f2xmOK56EudtdSkQ1JtSgYX
x9rf/97NfP8wI2x1IncQtwdWNdW5cvxMqYU/Za6WZvjNCnpFQGXLJQIDAQABowIw
ADANBgkqhkiG9w0BAQsFAAOCAQEARZ0h2ZeNXSme0EbQeJfEFOX+mj9rPkHIJFfQ
G7+dRG6DwDubxG56TsvUINcJX8O5C6oQ0T6dRutO/jG5LxJqmCz5wLUTA/6/YLDk
95gbYyJ/yfLm4sd6DEoXzWSld+EZ5b86pxFnvR/+cPY2tcSghQ+moZKR5THwHLsZ
hie2Pr6UVvuS5D9BC4ijR+cPyB5r4qliI9C1p8phuZctoX9dPpFY+UwkWgUDx9sz
UXFJsqueoibxfVqh4Jzdw+2XH6xN3WvTdJN4Sh1fqEpBeOxxxxxlRrCAJiMnLtG6
QgHF9ZnNRbIFcUHF/lyWY3oxcvgeUwEnE5QVVbdoMMGKKgffbQ==
-----END CERTIFICATE-----`;

export const CloudflareConnectToIdentityProvider: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Cloudflare SAML is a unique integration in that it sits between a Service
      Provider (application) and the Identity Provider. This allows for
      additional rules to be configured, but also means there are two
      connections that need to be made. The first necessary connection is
      between Cloudflare and the IdP, and the second connection is between the
      Service Provider and Cloudflare.
    </Text>

    <Text>
      First, create the connection between Cloudflare and the Identity Provider.
      Cloudflare Access allows you to connect with any IdP that supports a SAML
      2.0 connection.
    </Text>

    <Text>
      Follow the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://developers.cloudflare.com/cloudflare-one/identity/idp-integration/generic-saml"
      >
        documentation from Cloudflare
      </Link>{' '}
      to configure a SAML application connection between Cloudflare and your
      IdP.
    </Text>

    <Text>
      The one deviation from the CloudFlare documentation is that the SAML
      attributes must include <code>email</code>, <code>firstName</code>,{' '}
      <code>lastName</code>, and <code>id</code>. Email is included by default
      as the "Email attribute name", but you will need to add the other three as
      SAML attributes.
    </Text>

    <Text>
      When setting up the connection, be sure to enter <code>email</code>,{' '}
      <code>firstName</code>, <code>lastName</code>, and <code>id</code> as SAML
      attributes.
    </Text>

    <Img
      height={578}
      src="/images/9f0a2c53-f592-4ebc-96b9-87a276308d6e.png"
      width={655}
    />

    <Text>
      Save the connection and then click the "Test" button. When successful, you
      will see a success screen including your <code>saml_attributes</code> that
      have been added.
    </Text>

    <Img
      height={582}
      src="/images/23ab1c5b-1c6d-4420-9646-949cc863a0c8.png"
      width={842}
    />

    <Confirm
      label="I've connected Cloudflare with my IdP."
      onClick={handleNextStep}
    />
  </>
);

export const CloudflareCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Next, create the connection between Cloudflare and the Service Provider.
      From the Cloudflare Zero Trust dashboard Access menu, select
      "Applications", then "Add an application".
    </Text>

    <Img
      height={1076}
      src="/images/a8bf6645-2a6f-48cc-a6cc-c5b3cddbbef5.png"
      width={2678}
    />

    <Text>Select "SaaS" for the type of application.</Text>

    <Img
      height={1354}
      src="/images/3bfad18b-4059-4e3c-8095-f6ba608765f4.png"
      width={2488}
    />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      Select the name of your application you created in step 1 from the
      dropdown menu. If your application is not listed, type the name to save
      it.
    </Text>

    <Text>
      Paste the ACS URL and SP Entity ID to the corresponding fields in
      Cloudflare. Then select the Name ID Format that you would like to use for
      this application. For this example we’ll use Unique ID.
    </Text>

    <Img
      height={1352}
      src="/images/810bf2d0-d4d6-413d-8540-fbf3c1fd905b.png"
      width={2436}
    />

    <Confirm
      label="I've created a SAML application."
      onClick={handleNextStep}
    />
  </>
);

export const CloudflareConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Now, on this same page, Configure the attribute statements. The following
      attributes are required: <code>email</code>, <code>firstName</code>,{' '}
      <code>lastName</code>, and <code>id</code>. Cloudflare automatically sends{' '}
      <code>id</code> and <code>email</code>, so you just need to add{' '}
      <code>firstName</code> and <code>lastName</code>. These attributes were
      configured in Step 1, and the mapped values are the same here.
    </Text>

    <Text>
      Add <code>firstName</code> and <code>lastName</code> to both the right and
      left sides of the SAML attribute statements.
    </Text>

    <Img
      height={282}
      src="/images/af383c59-8648-4411-a16c-c3dcc8a307ae.png"
      width={983}
    />

    <Text>
      Select the Identity Provider that you are using from the list. In this
      example we are using an Okta SAML connection.
    </Text>

    <Img
      height={295}
      src="/images/cc4afcbb-b407-4833-905b-9a722a49d5d5.png"
      width={1118}
    />

    <Text>
      Configure at least one policy and one rule, then click next. For this
      example the Policy sets the session length to 30 minutes for everyone.
    </Text>

    <Img
      height={892}
      src="/images/4b167f60-14ec-458b-a308-184cde8f868e.png"
      width={2788}
    />

    <Confirm
      label="I've configured the attribute statements."
      onClick={handleNextStep}
    />
  </>
);

export const CloudflareFormatCertificate: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Cloudflare provides a Public Key value that needs to be formatted as an
      X.509 Certificate.
    </Text>

    <Text>
      To format the Public Key, copy the value to a text editor and add the
      following header and footer, along with two equal characters at the end of
      the Public Key, "{'=='}". Ensure there are no spaces above or below the
      Key value, then save with the file extension ".cert".
    </Text>

    <Card>
      <pre>
        <code>{CERTIFICATE_FORMAT}</code>
      </pre>
    </Card>

    <Text>
      The file should look like the below when you’re finished. Note that there
      are two equal characters, {'=='}, appended to the end of the Public Key.
      These must be added manually.
    </Text>

    <Card>
      <pre>
        <code>{CERTIFICATE_COMPLETE}</code>
      </pre>
    </Card>

    <Text>
      Save the file with a <code>.cert</code> extension. This <code>.cert</code>{' '}
      file will be uploaded in the next step.
    </Text>

    <Confirm
      label="I've formatted the Public Key as an X.509 Certificate."
      onClick={handleNextStep}
    />
  </>
);

export const CloudflareUploadMetadata: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  connectionUpdatedFields,
  setConnectionUpdatedFields,
  errors,
  isLoading,
  onInputChange: handleInputChange,
  onNextStep: handleNextStep,
  validationErrors,
}) => (
  <>
    <Text>
      Copy the "SSO Endpoint" and "Access Entity ID or Issuer" values and paste
      them in to the "IdP SSO URL" (SSO Endpoint) and "Identity Provider Issuer"
      (Access Entity ID) fields below.
    </Text>

    <Text>
      Upload the X.509 certificate that you formatted from the Public Key in
      step 4.
    </Text>

    <Img
      height={940}
      src="/images/b5ddb985-0483-4f32-85f9-7c1c3e029ba5.png"
      width={1621}
    />

    <Card>
      <Form
        disabled={
          !connection?.saml_idp_url ||
          !connectionUpdatedFields?.samlX509Certificates ||
          validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
        }
        isLoading={isLoading}
        isUpdate={!!(errors?.saml_idp_url || errors?.samlX509Certificates)}
        onSubmit={handleNextStep}
      >
        <InputGroup
          error={
            errors?.saml_idp_url?.message ||
            validationErrors?.saml_idp_url?.message
          }
          id="saml_idp_url"
          label="1. Identity Provider Single Sign-On URL"
          name="saml_idp_url"
          onChange={handleInputChange}
          placeholder={
            'https://your-team-id.cloudflareaccess.com/cdn-cgi/access/sso/saml/unique-id'
          }
          value={connection?.saml_idp_url ?? undefined}
        />

        <InputGroup
          id="saml_idp_entity_id"
          label="2. Identity Provider Issuer"
          name="saml_idp_entity_id"
          onChange={(event) =>
            setConnectionUpdatedFields((fields) => ({
              ...fields,
              samlIdpEntityId: event.target.value,
            }))
          }
          placeholder={'https://your-team-id.cloudflareaccess.com'}
          value={connectionUpdatedFields.samlIdpEntityId}
        />

        <FileField
          error={errors?.samlX509Certificates}
          label="3. X.509 Certificate"
          name="saml_x509_certs"
          onUpload={({ file }) => {
            setConnectionUpdatedFields({
              samlX509Certificates: [file.content],
            });
          }}
          value={connectionUpdatedFields?.samlX509Certificates?.[0]}
        />
      </Form>
    </Card>
  </>
);
