import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const OracleCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Follow the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://docs.oracle.com/en/cloud/paas/identity-cloud/uaids/add-saml-application.html"
      >
        Oracle Cloud documentation
      </Link>{' '}
      to create a new SAML application.
    </Text>

    <Text>
      Copy and paste the ACS URL and SP Entity ID into the corresponding fields
      for Service Provider details and configuration.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within Oracle. Provide the value below to your Oracle SAML
      application settings.
    </Text>

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      In the Advanced Settings of the SSO Configuration page, ensure that you
      select Signed SSO for Assertion and Response, and Include Signing
      Certificate in Signature.
    </Text>

    <Confirm
      label="I've created and configured a SAML application."
      onClick={handleNextStep}
    />
  </>
);

export const OracleConfigureClaims: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Expand the Attribute Configuration section on the SSO Configuration page
      and add the following 4 required attributes: firstName, lastName, email,
      and id.
    </Text>

    <Text>Ensure the following attribute mapping is set:</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={handleNextStep}
    />
  </>
);

export const OracleUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://myoracleserver.com/abc123..."
    idpSsoUrlPlaceholder="https://myoracleserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      Obtain the IdP Metadata URL following the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://docs.oracle.com/en/cloud/paas/identity-cloud/uaids/access-saml-metadata.html"
      >
        instructions from Oracle
      </Link>{' '}
      . Then paste the Metadata URL into the corresponding field below.
    </Text>

    <Text>
      Alternatively, you can manually configure the connection by providing the
      IdP URI (Entity ID), IdP SSO URL and X.509 Certificate.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>
  </MetadataUrlStep>
);
