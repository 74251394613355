import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { ProviderLogo } from 'components/provider-logo';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { MouseEvent, ReactElement } from 'react';
import { Check } from 'react-feather';

export interface ProviderBoxProps {
  active: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  provider: ConnectionType | DirectoryType;
  label?: string;
}

export const ProviderBox = ({
  active,
  label,
  provider,
  onClick: handleClick,
}: ProviderBoxProps): ReactElement => (
  <button
    className={classNames(
      'appearance-none border border-gray-lightmode-200 rounded w-[120px] h-[60px] transition cursor-pointer',
      'flex flex-col items-center justify-center relative bg-white',
      'hover:shadow-md',
      'focus:outline-none focus:border-primary',
      {
        'bg-gray-50 border-primary shadow-md': active,
      },
    )}
    onClick={handleClick}
  >
    <ProviderLogo
      height={Boolean(label) ? '14px' : '20px'}
      provider={provider}
      width="100px"
    />
    {label && (
      <Text as="p" size="small">
        {label}
      </Text>
    )}
    <CheckItem visible={active} />
  </button>
);

interface CheckProps {
  visible: boolean;
}

const CheckItem = ({ visible }: CheckProps) => (
  <div
    className={classNames(
      'w-6 h-6 rounded-full absolute -top-3 -right-2 flex items-center justify-center transition-opacity bg-primary',
      {
        'opacity-0': !visible,
        'opacity-1': visible,
      },
    )}
  >
    <Check className="stroke-white" size={16} strokeWidth={3} />
  </div>
);
