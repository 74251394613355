import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const VMWareCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>In your Workspace ONE Catalog, click "New".</Text>

    <Img
      priority
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-1.png"
      width={1278}
    />

    <Text>Provide a Name that describes {appName}, then click "Next".</Text>

    <Confirm
      label="I’ve created a new SaaS Application"
      onClick={handleNextStep}
    />
  </>
);

export const VMWareBasicConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the "Configuration" tab from the left sidebar.</Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>Submit the "URL/XML".</Text>

    <Img
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-2.png"
      width={1278}
    />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const VMWareAdvancedConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Continue scrolling and expand "Advanced Properties".</Text>

    <Img
      priority
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-3.png"
      width={1278}
    />

    <Text>Enable "Sign Assertion" and "Include Assertion Signature".</Text>

    <Img
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-4.png"
      width={1278}
    />

    <Confirm
      label='I’ve enabled "Sign Assertion" and "Include Assertion Signature"'
      onClick={handleNextStep}
    />
  </>
);

export const VMWareConfigureAttributeMap: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Continue scrolling until "Custom Attribute Mapping".</Text>

    <Img
      priority
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-5.png"
      width={1278}
    />

    <Text>Fill in the following attribute mappings and select "Next".</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>
      NOTE: Some VMware configurations use user.ExternalId instead of
      user.objectGUID. In this case, you would map the id attribute to
      user.ExternalId.
    </Text>

    <Img
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-6.png"
      width={1278}
    />

    <Confirm
      label="I’ve configured the Attribute Map"
      onClick={handleNextStep}
    />
  </>
);

export const VMWareUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://tenant.vmwareidentity.com/SAAS/API/1.0/GET/metadata/idp.xml..."
    idpSsoUrlPlaceholder="https://foocorp.workspaceoneaccess.com..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      After saving your SaaS Application, click "Settings" and then "SAML
      Metadata". Click on "Copy URL" next to "Identity Provider (IdP) metadata"
      and paste it below.
    </Text>

    <Img
      priority
      height={740}
      src="/admin-portal/sso/vmware/v1/vmware-9.png"
      width={1278}
    />
  </MetadataUrlStep>
);
