import {
  ProviderAdfs,
  ProviderAdp,
  ProviderAuth0,
  ProviderAzure,
  ProviderAzureAd,
  ProviderBambooHR,
  ProviderBreatheHr,
  ProviderCas,
  ProviderClassLink,
  ProviderCloudflare,
  ProviderCyberArk,
  ProviderDuo,
  ProviderFourthHr,
  ProviderGoogle,
  ProviderGoogleSaml,
  ProviderGusto,
  ProviderHibob,
  ProviderJumpCloud,
  ProviderKeycloak,
  ProviderMiniOrange,
  ProviderNetIq,
  ProviderOkta,
  ProviderOnelogin,
  ProviderOpenId,
  ProviderOracle,
  ProviderOther,
  ProviderPeopleHr,
  ProviderPingFederate,
  ProviderPingOne,
  ProviderRippling,
  ProviderSalesforce,
  ProviderSaml,
  ProviderScim,
  ProviderSimpleSaml,
  ProviderVMWare,
  ProviderWorkday,
} from '@workos-inc/component-library';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { ReactElement } from 'react';

export interface ProviderLogoProps {
  provider: ConnectionType | DirectoryType;
  width?: string;
  className?: string;
  height?: string;
}

export const ProviderLogo = ({
  className,
  height,
  width,
  provider,
}: ProviderLogoProps): ReactElement | null => {
  switch (provider) {
    case ConnectionType.Adfssaml:
      return (
        <ProviderAdfs className={className} height={height} width={width} />
      );
    case ConnectionType.AdpOidc:
      return (
        <ProviderAdp className={className} height={height} width={width} />
      );
    case ConnectionType.Auth0Saml:
      return (
        <ProviderAuth0 className={className} height={height} width={width} />
      );
    case ConnectionType.AzureSaml:
      return (
        <ProviderAzure className={className} height={height} width={width} />
      );
    case ConnectionType.CasSaml:
      return (
        <ProviderCas className={className} height={height} width={width} />
      );
    case ConnectionType.CloudflareSaml:
      return (
        <ProviderCloudflare
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.ClassLinkSaml:
      return (
        <ProviderClassLink
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.CyberArkSaml:
    case DirectoryType.CyberArkScimV2_0:
      return (
        <ProviderCyberArk className={className} height={height} width={width} />
      );
    case ConnectionType.DuoSaml:
      return (
        <ProviderDuo className={className} height={height} width={width} />
      );
    case ConnectionType.GoogleOAuth:
      return (
        <ProviderGoogle className={className} height={height} width={width} />
      );
    case ConnectionType.GoogleSaml:
      return (
        <ProviderGoogleSaml
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.JumpCloudSaml:
      return (
        <ProviderJumpCloud
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.KeycloakSaml:
      return (
        <ProviderKeycloak className={className} height={height} width={width} />
      );
    case ConnectionType.NetIqSaml:
      return (
        <ProviderNetIq className={className} height={height} width={width} />
      );
    case ConnectionType.OktaSaml:
      return (
        <ProviderOkta className={className} height={height} width={width} />
      );
    case ConnectionType.OneLoginSaml:
      return (
        <ProviderOnelogin className={className} height={height} width={width} />
      );
    case ConnectionType.OracleSaml:
      return (
        <ProviderOracle className={className} height={height} width={width} />
      );
    case ConnectionType.GenericOidc:
      return (
        <ProviderOpenId className={className} height={height} width={width} />
      );
    case ConnectionType.GenericSaml:
      return (
        <ProviderSaml className={className} height={height} width={width} />
      );
    case ConnectionType.MiniOrangeSaml:
      return (
        <ProviderMiniOrange
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.NetIqSaml:
      return (
        <ProviderNetIq className={className} height={height} width={width} />
      );
    case ConnectionType.PingFederateSaml:
      return (
        <ProviderPingFederate
          className={className}
          height={height}
          width={width}
        />
      );
    case DirectoryType.PingFederateScimV2_0:
      return (
        <ProviderPingFederate
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.PingOneSaml:
      return (
        <ProviderPingOne className={className} height={height} width={width} />
      );
    case ConnectionType.RipplingSaml:
      return (
        <ProviderRippling className={className} height={height} width={width} />
      );
    case ConnectionType.SalesforceSaml:
      return (
        <ProviderSalesforce
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.SimpleSamlPhpSaml:
      return (
        <ProviderSimpleSaml
          className={className}
          height={height}
          width={width}
        />
      );
    case ConnectionType.VMwareSaml:
      return (
        <ProviderVMWare className={className} height={height} width={width} />
      );
    case DirectoryType.AzureScimv2_0:
      return (
        <ProviderAzureAd className={className} height={height} width={width} />
      );
    case DirectoryType.BambooHr:
      return (
        <ProviderBambooHR className={className} height={height} width={width} />
      );
    case DirectoryType.BreatheHr:
      return (
        <ProviderBreatheHr
          className={className}
          height={height}
          width={width}
        />
      );
    case DirectoryType.FourthHr:
      return (
        <ProviderFourthHr className={className} height={height} width={width} />
      );
    case DirectoryType.GSuiteDirectory:
      return (
        <ProviderGoogle className={className} height={height} width={width} />
      );
    case DirectoryType.Gusto:
      return (
        <ProviderGusto className={className} height={height} width={width} />
      );
    case DirectoryType.Hibob:
      return (
        <ProviderHibob className={className} height={height} width={width} />
      );
    case DirectoryType.JumpCloudScimV2_0:
      return (
        <ProviderJumpCloud
          className={className}
          height={height}
          width={width}
        />
      );
    case DirectoryType.OktaScimv1_1:
    case DirectoryType.OktaScimv2_0:
      return (
        <ProviderOkta className={className} height={height} width={width} />
      );
    case DirectoryType.OneLoginScimV2_0:
      return (
        <ProviderOnelogin className={className} height={height} width={width} />
      );
    case DirectoryType.PeopleHr:
      return (
        <ProviderPeopleHr className={className} height={height} width={width} />
      );
    case DirectoryType.Rippling:
      return (
        <ProviderRippling className={className} height={height} width={width} />
      );
    case DirectoryType.GenericScimv1_1:
      return (
        <ProviderScim className={className} height={height} width={width} />
      );
    case DirectoryType.GenericScimv2_0:
      return (
        <ProviderScim className={className} height={height} width={width} />
      );
    case DirectoryType.Workday:
      return (
        <ProviderWorkday className={className} height={height} width={width} />
      );
    default:
      return <ProviderOther className={className} />;
  }
};
