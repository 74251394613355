import { TextInput } from '@workos-inc/component-library';
import classNames from 'classnames';
import { ProviderBox } from 'components/provider-box';
import { motion } from 'framer-motion';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useFeature } from 'utils/feature-flags';

interface ProviderSelectionProps<T> {
  isSearchable?: boolean;
  providers: readonly T[];
  selection?: T;
  providerLabels?: { [key: string]: string };
  onSelection: (provider: T) => void;
}

export const ProviderSelection = <T extends ConnectionType | DirectoryType>({
  providers,
  selection,
  onSelection,
  providerLabels,
  isSearchable = false,
}: Readonly<ProviderSelectionProps<T>>): ReactElement => {
  const [searchInput, setSearchInput] = useState('');
  const isGoogleOAuthEnabled = useFeature('googleOAuthProvider');
  const oktaAdminPortalEnabledForDrata = useFeature(
    'oktaAdminPortalEnabledForDrata',
  );
  const isHibobEnabled = useFeature('hibobAdminPortal');
  const isWorkdayEnabled = useFeature('workdayAdminPortal');
  const isFourthHrEnabled = useFeature('fourthHrAdminPortal');
  const isRipplingSsoEnabled = useFeature('ripplingSsoAdminPortal');
  const isBreatheHrEnabled = useFeature('breatheHrAdminPortal');
  const isAdpOidcEnabled = useFeature('adpOidcAdminPortal');
  const isPeopleHrEnabled = useFeature('peopleHrAdminPortal');

  return (
    <div className="flex flex-col items-center max-w-xl">
      {isSearchable && (
        <TextInput
          className="mb-8"
          id="search"
          name="search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearchInput(event.target.value)
          }
          placeholder="Search identity providers..."
          type="search"
          value={searchInput}
        />
      )}
      <motion.ul
        animate="show"
        className={classNames('grid gap-4 mb-4', {
          'grid-cols-4': providers.length > 4,
          'grid-cols-2': providers.length <= 4,
        })}
        initial="hidden"
        variants={listVariants}
      >
        {providers
          .filter((provider) => {
            if (provider === ConnectionType.GoogleOAuth) {
              return !!isGoogleOAuthEnabled;
            } else if (provider === ConnectionType.OktaSaml) {
              return !!oktaAdminPortalEnabledForDrata;
            } else if (provider === DirectoryType.OktaScimv1_1) {
              return !!oktaAdminPortalEnabledForDrata;
            } else if (provider === DirectoryType.OktaScimv2_0) {
              return !!oktaAdminPortalEnabledForDrata;
            } else if (provider === DirectoryType.Hibob) {
              return !!isHibobEnabled;
            } else if (provider === DirectoryType.Workday) {
              return !!isWorkdayEnabled;
            } else if (provider === DirectoryType.FourthHr) {
              return !!isFourthHrEnabled;
            } else if (provider === ConnectionType.RipplingSaml) {
              return !!isRipplingSsoEnabled;
            } else if (provider === DirectoryType.BreatheHr) {
              return !!isBreatheHrEnabled;
            } else if (provider === ConnectionType.AdpOidc) {
              return !!isAdpOidcEnabled;
            } else if (provider === DirectoryType.PeopleHr) {
              return !!isPeopleHrEnabled;
            }

            return provider;
          })
          .filter((provider) => provider.match(new RegExp(searchInput, 'i')))
          .map((provider) => (
            <motion.li
              key={provider}
              data-testid={`provider-item__${
                provider as ConnectionType | DirectoryType
              }`}
              variants={itemVariants}
            >
              <ProviderBox
                active={selection === provider}
                label={providerLabels?.[provider]}
                onClick={() => onSelection(provider)}
                provider={provider}
              />
            </motion.li>
          ))}
      </motion.ul>
    </div>
  );
};

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.1 },
  show: { opacity: 1, scale: 1 },
};
